import { Show, splitProps } from "solid-js";
import { SubTitleProps } from "./model";

export function Subtitle(props: SubTitleProps) {
  // {sub_title, clases, editable, onSubtitleChange, hasValue}

  return (
    <Show
      when={props.options.editable}
      fallback={
        <p class={` text-14px w-412px text#highlight py-10px  ${props.class ?? ""}`}>{props.elements.sub_title}</p>
      }
    >
      <textarea
        class={`w-full text#n-200 border-dashed border-2 border#n-200 text-14px p-10px overflow-hidden placeholder:text#n-200 ${props.class}`}
        value={props.elements.sub_title}
        onInput={props.events?.onChange}
      />
    </Show>
  );
}
